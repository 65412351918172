<template>
  <div>
    <v-container>
        <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
            Gestión de cuadrantes
        </div>

        <!-- Filtros -->
        <v-form :disabled="loading">
            <v-row>
                <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                    <DateFind label="Fecha" v-model="filtros.Fecha"></DateFind>
                </v-col>
                <v-col cols="12" sm="6" md="5" lg="5" xl="4">
                    <PerFind
                        class="mb-n7" ref="perFind" 
                        :perCatIdAnyLevel="101" 
                        label="Empleado"
                        v-model="filtros.Per">
                    </PerFind>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                    <DirFind
                        class="mr-2 mb-n7" ref="dirFind"
                        label="Tienda"  
                        v-model="filtros.Dir">
                    </DirFind>
                </v-col>
            </v-row>
        </v-form>

        <v-row style="height:60px" no-gutters class="mt-2">
            <v-col style="width: 120px" cols="auto">
                <v-btn color="primary" @click="loadData">
                    Mostrar
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers2"
                    :items="dataRows"
                    :options.sync="options"
                    :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                    :loading="loading"
                    @click:row="onClickRow"
                    style="overflow: scroll"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    dense>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.Semana="{ item }">
                        {{formatDate(item.Semana)}}
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.Fecha="{ item }">
                        {{formatDate(item.Fecha)}}
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.HoraEntrada="{ item }">
                        {{formatTime(item.HoraEntrada)}}
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.HoraSalida="{ item }">
                        {{formatTime(item.HoraSalida)}}
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.DescansoNormal="{ item }">
                        <v-simple-checkbox v-model="item.DescansoNormal" disabled v-ripple></v-simple-checkbox>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.DescansoLargo="{ item }">
                        <v-simple-checkbox v-model="item.DescansoLargo" disabled v-ripple></v-simple-checkbox>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.Horas="{ item }">
                        {{formatHoras(item.Horas)}}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <!-- Leyenda -->
        <v-row justify="center">
            <v-col cols="auto">
                <v-expansion-panels inset>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Leyenda</v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <v-simple-table dense id="tabla_leyenda">
                                <template v-slot:default>
                                    <tbody v-once>
                                        <tr v-for="item in leyenda" :key="item.code">
                                            <td>{{item.code}}</td>
                                            <td>{{item.name}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>

    <!-- Modal edición -->
    <v-dialog
        v-model="showEditDialog"
        transition="dialog-bottom-transition"
        persistent
        width="500">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                EDITAR TURNO
            </v-card-title>

            <v-card-text class="mt-2">
                <div v-if="editItem != null">
                    <!-- Datos básicos no editables -->
                    <p>Tienda: {{editItem.Tienda}}</p>
                    <p>Dependienta: {{editItem.Dependienta}}</p>
                    <p>
                        <span class="mr-3 d-inline-block">Semana: {{formatDate(editItem.Semana)}}</span>
                        <span class="d-inline-block">Día: {{formatDate(editItem.Fecha)}}</span>
                    </p>
                    <!-- Hora de entrada/salida -->
                    <v-row no-gutters class="mb-3">
                        <v-col cols="6" sm="4" md="4" lg="3" xl="3">
                            <v-text-field
                                class="mr-1" outlined dense
                                hide-details
                                type="number" min="0" max="23"
                                v-model="editItem.Entrada.Hora"
                                label="Hora (entrada)"
                                @change="onChangeTime">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" md="4" lg="3" xl="3">
                            <v-text-field
                                outlined dense
                                hide-details
                                type="number" min="0" max="59"
                                v-model="editItem.Entrada.Minuto"
                                label="Minuto (entrada)"
                                @change="onChangeTime">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col cols="6" sm="4" md="4" lg="3" xl="3">
                            <v-text-field
                                class="mr-1" outlined dense
                                hide-details
                                type="number" min="0" max="23"
                                v-model="editItem.Salida.Hora"
                                label="Hora (salida)"
                                @change="onChangeTime">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" md="4" lg="3" xl="3">
                            <v-text-field
                                outlined dense
                                hide-details
                                type="number" min="0" max="59"
                                v-model="editItem.Salida.Minuto"
                                label="Minuto (salida)"
                                @change="onChangeTime">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!-- VBPI -->
                    <div>
                        <v-select
                            class="mb-3"
                            dense outlined
                            hide-details
                            v-model="editItem.vbpi"
                            :items="leyendaOptions"
                            item-value="code"
                            item-text="name"
                            label="vbpi"
                            :menu-props="{ offsetY: true }">
                        </v-select>
                    </div>
                    <!-- Descansos -->
                    <div>
                        <v-checkbox
                            class="mb-3"
                            hide-details
                            v-model="editItem.DescansoNormal"
                            label="Descanso normal">
                        </v-checkbox>
                    </div>
                    <div>
                        <v-checkbox
                            class="mb-3"
                            hide-details
                            v-model="editItem.DescansoLargo"
                            label="Descanso largo">
                        </v-checkbox>
                    </div>

                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="primary"
                    @click="onBtnSave">
                    Aceptar
                </v-btn>
                <v-btn @click="onBtnCancelEdit">
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import DirFind from "../components/DirFind.vue";
import DateFind from "../components/DateFind.vue";
import PerFind from "../components/PerFind.vue";
import funcs from "../funcs";
import { mapState } from "vuex";

export default {
    components:{
        DirFind, DateFind, PerFind
    },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        leyendaOptions: function() {
            const emptyOptions = [{code: '', name: ''}];
            return emptyOptions.concat(this.leyenda);
        },
        // dataRowsComputed: function () {
        //     let cuadranteEmpleados = [];

        //     // Mapeo de los datos de la api al formato de la tabla
        //     let dptaAnt = "-";
        //     let cuadranteObj = {};
        //     let ultElemIndex = 0;
        //     const formatoHora = new Intl.NumberFormat('es-ES', {style: 'decimal', minimumFractionDigits:2, maximumFractionDigits: 2});
            
        //     this.apiData.forEach(element => {
        //         if (element.Dependienta != null && element.Dependienta !== '')
        //         {
        //             if (element.Dependienta !== dptaAnt) {
        //                 cuadranteObj = { 
        //                     Dependienta: element.Dependienta,
        //                     Tienda: element.Tienda,
        //                     Lunes: '',
        //                     Martes: '',
        //                     Miercoles: '',
        //                     Jueves: '',
        //                     Viernes: '',
        //                     Sabado: '',
        //                     Domingo: '',
        //                     TotalSemana: '',
        //                     ResumenFecha: element.ResumenFecha
        //                 };
        //                 cuadranteEmpleados.push(cuadranteObj);
        //                 ultElemIndex = cuadranteEmpleados.length - 1;
        //             }

        //             const fecha = new Date(element.Fecha);
        //             const diaSemana = fecha.getDay(fecha);
        //             var infoDiaObj = {
        //                 Horario: element.Turnos,
        //                 Horas: formatoHora.format(element.Horas),
        //                 Excepcion: element.vbpi,
        //                 apiData: element
        //             };

        //             if (diaSemana == 1) { // Lunes
        //                 cuadranteEmpleados[ultElemIndex].Lunes = infoDiaObj;
        //             }
        //             else if (diaSemana == 2){
        //                 cuadranteEmpleados[ultElemIndex].Martes = infoDiaObj;
        //             }
        //             else if (diaSemana == 3){
        //                 cuadranteEmpleados[ultElemIndex].Miercoles = infoDiaObj;
        //             }
        //             else if (diaSemana == 4){
        //                 cuadranteEmpleados[ultElemIndex].Jueves = infoDiaObj;
        //             }
        //             else if (diaSemana == 5){
        //                 cuadranteEmpleados[ultElemIndex].Viernes = infoDiaObj;
        //             }
        //             else if (diaSemana == 6){
        //                 cuadranteEmpleados[ultElemIndex].Sabado = infoDiaObj;
        //             }
        //             else if (diaSemana == 0 && !element.ResumenSemana){
        //                 cuadranteEmpleados[ultElemIndex].Domingo = infoDiaObj;
        //             }
        //             else if (diaSemana == 0 && element.ResumenSemana){
        //                 cuadranteEmpleados[ultElemIndex].TotalSemana = infoDiaObj;
        //             }

        //             dptaAnt = element.Dependienta;
        //         }

        //     });
            
        //     return cuadranteEmpleados;
        // }
    },
    data () {
        return {
            loading: false,
            filtros: {
                Fecha: '',
                Dir: null,
                Per: null
            },
            apiData: [],
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'TIENDA', sortable: false, value: 'Tienda', width: "200" },
                { text: 'DEPENDIENTA', sortable: false, value: 'Dependienta', width: "250" },
                { text: 'LUNES', sortable: false, value: 'Lunes', width: "147", align: 'center' },
                { text: 'MARTES', sortable: false, value: 'Martes', width: "147", align: 'center' },
                { text: 'MIERCOLES', sortable: false, value: 'Miercoles', width: "147", align: 'center' },
                { text: 'JUEVES', sortable: false, value: 'Jueves', width: "147", align: 'center' },
                { text: 'VIERNES', sortable: false, value: 'Viernes', width: "147", align: 'center' },
                { text: 'SABADO', sortable: false, value: 'Sabado', width: "147", align: 'center' },
                { text: 'DOMINGO', sortable: false, value: 'Domingo', width: "147", align: 'center' },
                { text: 'TOTAL', sortable: false, value: 'TotalSemana', width: "147", align: 'center' },
            ],
            headers2: [
                { text: 'TIENDA', sortable: false, value: 'Tienda', width: "200" },
                { text: 'DEPENDIENTA', sortable: false, value: 'Dependienta', width: "250" },
                { text: 'SEMANA', sortable: false, value: 'Semana', width: "105", align: 'center' },
                { text: 'DIA', sortable: false, value: 'Fecha', width: "105", align: 'center' },
                { text: 'ENTRADA', sortable: false, value: 'HoraEntrada', width: "85", align: 'center' },
                { text: 'SALIDA', sortable: false, value: 'HoraSalida', width: "85", align: 'center' },
                { text: 'VBPI', sortable: false, value: 'vbpi', width: "85", align: 'center' },
                { text: 'DESCANSO NORMAL', sortable: false, value: 'DescansoNormal', width: "147", align: 'center' },
                { text: 'DESCANSO LARGO', sortable: false, value: 'DescansoLargo', width: "147", align: 'center' },
                { text: 'HORAS', sortable: false, value: 'Horas', width: "100", align: 'center' },
            ],
            leyenda: [
              { code: 'A', name: 'ASUNTOS PROPIOS' },
              { code: 'B', name: 'BAJA MEDICA' },
              { code: 'BO', name: 'BODA' },
              { code: 'C', name: 'CURSO O REUNION' },
              { code: 'CI', name: 'CITACION OFICIAL' },
              { code: 'D', name: 'DESCANSO SEMANAL' },
              { code: 'E', name: 'DESCANSO POR SABADO FESTIVO' },
              { code: 'ES', name: 'SUSPENSIÓN DE EMPLEO Y SUELDO' },
              { code: 'F', name: 'FESTIVO O ABSTENCION' },
              { code: 'FF', name: 'FALLECIMIENTO FAMILIAR' },
              { code: 'H', name: 'HUELGA' },
              { code: 'I', name: 'INGRESO HOSPITALARIO' },
              { code: 'M', name: 'ASISTENCIA MEDICO' },
              { code: 'MU', name: 'MUDANZA' },
              { code: 'N', name: 'NEGOCIACION SINDICAL' },
              { code: 'P', name: 'PERMISO RETRIBUIDO' },
              { code: 'R', name: 'RECUPERACION ASUNTOS PROPIOS' },
              { code: 'S', name: 'HORAS SINDICALES' },
              { code: 'V', name: 'VACACIONES' },
              { code: 'X', name: 'ACUMULACIÓN DE HORAS COVID' },
              { code: 'Z', name: 'RECUPERACIÓN DE HORAS COVID' }
            ],
            showEditDialog: false,
            editItem: null
        }
    },
    watch: {},
    mounted() {
        // console.log(this)
        // this.$parent.test()
        var now = new Date();
        this.filtros.Fecha =  
            now.getFullYear() + '-' + 
            (now.getMonth() + 1).toString().padStart(2, '0') + '-' + 
            now.getDate().toString().padStart(2, '0');
    },
    methods: {
        getCuadrantesFromApi() {
            this.loading = true;

            const dirId = this.filtros.Dir != null ? this.filtros.Dir.DirId : null;
            const perId = this.filtros.Per != null ? this.filtros.Per.PerId : null;

            return new Promise((resolve) => {
                var controllerParameters = {
                    Action: 'GET_CUADRANTE_SEMANAL',
                    Fecha: this.filtros.Fecha,
                    DirId: dirId,
                    PerId: perId
                };
                var AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/cuadrante", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result => {
                    const success = result.data.Success;
                    const message = result.data.Message;
                    const items = result.data.Cuadrante;

                    this.loading = false;
                    resolve({
                        items,
                        success,
                        message
                    });
                })
                .catch(error => {
                    if(error != null) {
                        this.loading = false;
                        alert('Error al cargar los turnos.');
                    }
                });
            });
        },
        saveTurnoToApi() {
            this.loading = true;

            return new Promise((resolve) => {
                var controllerParameters = {
                    Action: 'SAVE_TURNO',
                    Turno: this.editItem
                };
                var AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/cuadrante", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result => {
                    const success = result.data.Success;
                    const message = result.data.Message;

                    this.loading = false;
                    resolve({
                        success,
                        message
                    });
                })
                .catch(error => {
                    if(error != null) {
                        this.loading = false;
                        alert('Error al guardar el turnos.');
                    }
                });
            });
        },
        loadData() {
            this.getCuadrantesFromApi()
            .then(data => {
                if (!data.success) {
                    alert(data.message);
                }
                else {
                    //data.items.sort(this.compararArrayCuadrantes);
                    this.apiData = data.items;
                    this.dataRows = data.items;
                    //const fechaSemana = new Date(data.items[0].Semana);
                    //this.refreshHeaderDates(fechaSemana);
                }
            });
        },
        saveData() {
            this.saveTurnoToApi()
            .then(data => {
                if (!data.success) {
                    alert(data.message);
                }
                else {
                    alert('Turno guardado correctamente.');
                    this.editItem = null;
                    this.showEditDialog = false;
                    this.loadData();
                }
            });
        },
        onClickRow(item) {
            // console.log(item);
            if(this.loading) { return; }

            // Hora y minuto se editarán como numeros enteros
            const horaEntradaEntero = Math.trunc(item.HoraEntrada);
            const horaSalidaEntero = Math.trunc(item.HoraSalida);

            // Mapeo del turno a editar
            this.editItem = {
                Codigo: item.Codigo,
                CodigoTienda: item.CodigoTienda,
                Tienda: item.Tienda,
                CodigoDependienta: item.CodigoDependienta,
                Dependienta: item.Dependienta,
                Semana: item.Semana,
                Fecha: item.Fecha,
                HoraEntrada: item.HoraEntrada,
                HoraSalida: item.HoraSalida,
                vbpi: item.vbpi,
                DescansoNormal: item.DescansoNormal,
                DescansoLargo: item.DescansoLargo,
                Horas: item.Horas,
                Entrada: {
                    Hora: horaEntradaEntero,
                    Minuto: Math.round((item.HoraEntrada - horaEntradaEntero) * 100)
                },
                Salida: {
                    Hora: horaSalidaEntero,
                    Minuto: Math.round((item.HoraSalida - horaSalidaEntero) * 100)
                }
            };

            // Mostramos el dialogo de edicion
            this.showEditDialog = true;
        },
        onBtnSave() {
            if(this.loading) { return; }
            // Calculamos el valor decimal que se guardara en hora entrada/salida. Por ejem: 8:05 -> 8,05
            this.editItem.HoraEntrada = this.editItem.Entrada.Hora + (this.editItem.Entrada.Minuto / 100);
            this.editItem.HoraSalida = this.editItem.Salida.Hora + (this.editItem.Salida.Minuto / 100);
            // console.log(this.editItem);
            this.saveData();
        },
        onBtnCancelEdit() {
            this.showEditDialog = false;
            this.editItem = null;
        },
        // compararArrayCuadrantes (a, b) {
        //     if (a.ResumenFecha){
        //         return 1;
        //     }
        //     else if (b.ResumenFecha){
        //         return -1;
        //     }
        //     else{
        //         if (a.Dependienta > b.Dependienta) {
        //         return 1;
        //         }
        //         if (a.Dependienta < b.Dependienta) {
        //         return -1;
        //         }
        //     }
        //     // a must be equal to b
        //     return 0;
        // },
        // refreshHeaderDates(weekStart) {
        //     const lunesIndex = this.headers.findIndex(f => f.value == 'Lunes');
            
        //     if(lunesIndex !== -1) {
        //         const date = new Date(weekStart);
        //         this.headers[lunesIndex].text = 'LUNES ' + funcs.UtcDateTimeToStr(date, 'date');
        //         this.headers[lunesIndex + 1].text = 'MARTES ' + funcs.UtcDateTimeToStr(date.setDate(date.getDate() + 1), 'date');
        //         this.headers[lunesIndex + 2].text = 'MIERCOLES ' + funcs.UtcDateTimeToStr(date.setDate(date.getDate() + 1), 'date');
        //         this.headers[lunesIndex + 3].text = 'JUEVES ' + funcs.UtcDateTimeToStr(date.setDate(date.getDate() + 1), 'date');
        //         this.headers[lunesIndex + 4].text = 'VIERNES ' + funcs.UtcDateTimeToStr(date.setDate(date.getDate() + 1), 'date');
        //         this.headers[lunesIndex + 5].text = 'SABADO ' + funcs.UtcDateTimeToStr(date.setDate(date.getDate() + 1), 'date');
        //         this.headers[lunesIndex + 6].text = 'DOMINGO ' + funcs.UtcDateTimeToStr(date.setDate(date.getDate() + 1), 'date');
        //     }
        // },
        onChangeTime() {
            // Corregimos los valores invalidos de los tiempos
            this.editItem.Entrada.Hora = this.fixTimeValue(this.editItem.Entrada.Hora, 0, 23);
            this.editItem.Entrada.Minuto = this.fixTimeValue(this.editItem.Entrada.Minuto, 0, 59);
            this.editItem.Salida.Hora = this.fixTimeValue(this.editItem.Salida.Hora, 0, 23);
            this.editItem.Salida.Minuto = this.fixTimeValue(this.editItem.Salida.Minuto, 0, 59);
        },
        fixTimeValue(value, min, max) {
            if (value == null || value === '') return min;
            const fixValue = Math.trunc(value);
            if(fixValue < min) return min;
            else if(fixValue > max) return max;
            return Number(fixValue);
        },
        formatDate(data) {
            const date = new Date(data);
            return funcs.UtcDateTimeToStr(date, 'date');
        },
        formatHoras(data) {
            const formatoHora = new Intl.NumberFormat('es-ES', {style: 'decimal', minimumFractionDigits:2, maximumFractionDigits: 2});
            return formatoHora.format(data);
        },
        formatTime(data) {
            const formatoHora = new Intl.NumberFormat('es-ES', {style: 'decimal', minimumFractionDigits:2, maximumFractionDigits: 2});
            const timeString = formatoHora.format(data).replace(',',':');
            return timeString;
        }
    },
}
</script>
